//新建病患
<template>
	<div class="doctor_management">
		<el-row class="bread_crumbs">
			<el-breadcrumb separator="/">
				<el-breadcrumb-item :to="{ path: '/PatientManagement' }">病患管理</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: '/PatientManagement' }">病患信息</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: '/EditorPatien' }">编辑病患信息</el-breadcrumb-item>
			</el-breadcrumb>
		</el-row>
		<div class="public_no">
			<el-card class="box-card">
				<div slot="header" class="clearfix">
					<span>病患基本信息</span>
				</div>
				<el-form ref="form" label-position="right" label-width="80px" :model="form" :rules="rules">
					<el-row type="flex">
						<el-col :span="6">
							<el-form-item v-if="types=='200030'" label="医院:" prop="hospitalName">
								<span>{{form.hospitalName}}</span>
								<!-- <el-input  :disabled="true" v-model="form.hospitalName" placeholder="请输入"></el-input> -->
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item v-if="types=='200030'" label="科室:" prop="departmentsName">
								<span>{{form.departmentsName}}</span>
								<!-- <el-input  :disabled="true" v-model="form.departmentsName" placeholder="请输入"></el-input> -->
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item v-if="types=='200030'" label="医生:" prop="userName">
								<span>{{form.userName}}</span>
								<!-- <el-input  :disabled="true" v-model="form.userName" placeholder="请输入"></el-input> -->
							</el-form-item>
						</el-col>
						<el-col :span="6">
						</el-col>
					</el-row>
					<el-row type="flex">
						<el-col :span="6">
							<el-form-item label="病历号:" prop="medicalNo">
								<el-input @input="changeinput($event)" :disabled="dataCode ? true : false" clearable v-model="form.medicalNo"
								 placeholder="请输入"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item label="手机号码:" prop="phoneNumber">
								<el-input :disabled="businessStatus=='2' ? true : false" clearable v-model="form.phoneNumber" placeholder="请输入"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="6">
						</el-col>
					</el-row>
					<el-row type="flex">
						<el-col :span="6">
							<el-form-item label="姓名:" prop="fullName">
								<el-input :disabled="businessStatus=='2' ? true : false" clearable v-model="form.fullName" placeholder="请输入"></el-input>
							</el-form-item>

						</el-col>
						<el-col :span="6">
							<el-form-item label="年龄:" prop="age">
								<el-input :disabled="businessStatus=='2' ? true : false" clearable v-model="form.age" placeholder="请输入"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item label="性别:" prop="gender">
								<el-select :disabled="businessStatus=='2' ? true : false" filterable clearable v-model="form.gender"
								 placeholder="请选择" class="select-sty">
									<el-option v-for="item in SexOptions" :key="item.no" :label="item.label" :value="item.no">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
					</el-row>

					<el-row type="flex">
						<el-col :span="6">
							<el-form-item label="住址:" prop="Provinces">
								<el-cascader :disabled="businessStatus=='2' ? true : false" v-model="form.Provinces" clearable style="width:100%"
								 placeholder="请选择城市" filterable :props="{ value: 'id', label: 'executiveName'}" :options="ProvincesOptions">
								</el-cascader>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label-width="1px" label="" prop="address">
								<el-input :disabled="businessStatus=='2' ? true : false" clearable v-model="form.address" placeholder="请输入详细住址"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="6">
						</el-col>
					</el-row>
				</el-form>
			</el-card>
			<el-card class="box-card" >
				<div slot="header" class="clearfix">
					<span>医疗信息</span>
				</div>
				<el-form ref="form1" label-position="right" label-width="100px" :model="form1" :rules="rules1">
					<el-row type="flex" justify="space-around">
						<el-col :span="6">
							<el-form-item label="植入日期:" prop="implantationDate">
								<el-date-picker :disabled="businessStatus=='2' ? true : false" value-format="yyyy-MM-dd HH:mm:ss" clearable
								 type="datetime" placeholder="请选日期" v-model="form1.implantationDate" style="width: 100%;"></el-date-picker>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<!--<el-form-item label="首次问诊时间" prop="firstConsultationDate">
							<el-date-picker :disabled="businessStatus=='2' ? true : false" value-format="yyyy-MM-dd HH:mm:ss" clearable type="datetime" placeholder="请选择首次问诊时间" v-model="form1.firstConsultationDate"
							 style="width: 100%;"></el-date-picker>
						</el-form-item>-->
						</el-col>
						<el-col :span="12">
						</el-col>
					</el-row>
					<el-row type="flex" justify="space-around">
						<el-col :span="6">
							<el-form-item label="术者:" prop="userId1">
								<el-select clearable :disabled="businessStatus=='2' ? true : false" filterable v-model="form1.userId1"
								 placeholder="请选择" class="select-sty" @change="updataOprID">
									<el-option v-for="item in UserCurrencyOptions" :key="item.no" :label="item.label" :value="item.no">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item label="术者2:" prop="userId2">
								<el-input clearable :disabled="businessStatus=='2' ? true : false" v-model="form1.userId2" placeholder="请输入"
								 @input="changeinput($event)"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item label="术者3:" prop="userId3">
								<el-input clearable :disabled="businessStatus=='2' ? true : false" v-model="form1.userId3" placeholder="请输入"
								 @input="changeinput($event)"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="6">
						</el-col>
					</el-row>
					<el-row type="flex" justify="space-around">
						<el-col :span="6">
							<el-form-item label="术前诊断:" prop="preoperativeDiagnosis">
								<el-select :disabled="businessStatus=='2' ? true : false" clearable filterable v-model="form1.preoperativeDiagnosis"
								 placeholder="请选择" class="select-sty" @change="preoperativeDiagnosisChange($event)">
									<el-option v-for="item in DiagnosisOptions" :key="item.no" :label="item.label" :value="item.no">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item label="手术类型:" prop="operationType">
								<el-select clearable :disabled="businessStatus=='2' ? true : false" filterable v-model="form1.operationType"
								 class="select-sty" placeholder="请选择">
									<el-option v-for="item in OperationOptions" :key="item.no" :label="item.label" :value="item.no">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item label="术式:" prop="operationStyle">
								<el-select :disabled="businessStatus=='2' ? true : false" filterable clearable v-model="form1.operationStyle"
								 class="select-sty" placeholder="请选择">
									<el-option v-for="item in WarlockOptions" :key="item.no" :label="item.label" :value="item.no">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="6">
						</el-col>
					</el-row>
					<el-row type="flex" justify="space-around" v-if="form1.preoperativeDiagnosis==200049">
						<el-col :span="14">
							<el-form-item label="植入原因备注:" prop="preoperativeDiagnosisInfo">
								<el-input :disabled="businessStatus=='2' ? true : false" clearable type="textarea" :rows="2" placeholder="请输入植入原因备注"
								 v-model="form1.preoperativeDiagnosisInfo">
								</el-input>
							</el-form-item>
						</el-col>
						<el-col :span="10">
						</el-col>
					</el-row>
					<el-row type="flex" justify="space-around">
						<el-col :span="6">
							<el-form-item label="疾病诊断:" prop="diseaseDiagnosis">
								<el-select :disabled="businessStatus=='2' ? true : false" clearable filterable v-model="form1.diseaseDiagnosis"
								 placeholder="请选择" class="select-sty" @change="diseaseDiagnosisInfoChange($event)">
									<el-option v-for="item in DiseaseDiagnosisOptions" :key="item.no" :label="item.label" :value="item.no">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item label="是否更换产品:" prop="productReplacement">
								<el-select :disabled="businessStatus=='2' ? true : false" filterable clearable v-model="form1.productReplacement"
								 placeholder="请选择" class="select-sty">
									<el-option v-for="item in productReplacementOptions" :key="item.no" :label="item.label" :value="item.no">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item label="是否升级:" prop="upgrade">
								<el-select clearable :disabled="businessStatus=='2' ? true : false" filterable v-model="form1.upgrade"
								 placeholder="请选择" class="select-sty">
									<el-option v-for="item in upgradeOptions" :key="item.no" :label="item.label" :value="item.no">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="6">
						</el-col>
					</el-row>
					<el-row type="flex" justify="space-around" v-if="form1.diseaseDiagnosis==200060">
						<el-col :span="14">
							<el-form-item label="疾病诊断备注:" prop="diseaseDiagnosisInfo">
								<el-input :disabled="businessStatus=='2' ? true : false" clearable type="textarea" :rows="2" placeholder="请输入疾病诊断备注"
								 v-model="form1.diseaseDiagnosisInfo">
								</el-input>
							</el-form-item>
						</el-col>
						<el-col :span="10">
						</el-col>
					</el-row>
					<el-row type="flex" justify="space-around">
						<el-col :span="14">
							<el-form-item label="病史简介:" prop="preoperativeDiagnosisOther">
								<el-input clearable :disabled="businessStatus=='2' ? true : false" type="textarea" :rows="2" placeholder="请输入"
								 v-model="form1.preoperativeDiagnosisOther">
								</el-input>
							</el-form-item>
						</el-col>
						<el-col :span="10">
						</el-col>
					</el-row>
					<el-row type="flex" justify="space-around">
						<el-col :span="6">
							<el-form-item label="手术耗时:" prop="operationTime">
								<el-input clearable :disabled="businessStatus=='2' ? true : false" placeholder="请输入" v-model="form1.operationTime">
								</el-input>
							</el-form-item>
						</el-col>
						<el-col :span="18">
						</el-col>
					</el-row>
					<el-row type="flex" justify="space-around">
						<el-col :span="6">
							<el-form-item label="导管室:" prop="catheterRoom">
								<el-input :disabled="businessStatus=='2' ? true : false" clearable v-model="form1.catheterRoom" placeholder="请输入"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item label="三维" prop="threeDimensional">
								<el-input :disabled="businessStatus=='2' ? true : false" clearable v-model="form1.threeDimensional" placeholder="请输入"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item label="多导:" prop="multichannel">
								<el-input :disabled="businessStatus=='2' ? true : false" clearable v-model="form1.multichannel" placeholder="请输入"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="6">
						</el-col>
					</el-row>
					<el-row type="flex" justify="space-around">
						<el-col :span="12">
							<el-form-item label="并发症:" prop="complication">
								<el-input :disabled="businessStatus=='2' ? true : false" clearable v-model="form1.complication" placeholder="请输入"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="8">
							<el-form-item label-width="170px" label="术中有无发现其他植入物:" prop="implantsOther">
								<el-select clearable :disabled="businessStatus=='2' ? true : false" filterable v-model="form1.implantsOther"
								 placeholder="请选择" class="select-sty">
									<el-option v-for="item in implantsOtherOptions" :key="item.no" :label="item.label" :value="item.no">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="4">
						</el-col>
					</el-row>
					<el-row type="flex" justify="space-around">
						<el-col :span="14">
							<el-form-item label="特殊情况备注:" prop="specialRemarks">
								<el-input clearable :disabled="businessStatus=='2' ? true : false" type="textarea" :rows="2" placeholder="请输入特殊情况备注"
								 v-model="form1.specialRemarks">
								</el-input>
							</el-form-item>
						</el-col>
						<el-col :span="10">
						</el-col>
					</el-row>
					<el-card class="box-card">
						<div slot="header" class="clearfix">
							<span>术中测试结果</span>
						</div>
						<el-card class="box-card margin_top">
							<div slot="header" class="clearfix">
								<span>部位：右心房</span>
							</div>
							<el-row type="flex" justify="space-around">
								<el-col :span="6">
									<el-form-item label="阈值:" prop="rightAtriumStimulusThreshold">
										<el-input :disabled="businessStatus=='2' ? true : false" clearable v-model="form1.rightAtriumStimulusThreshold"
										 placeholder="请输入">
											<template slot="append">V</template>
										</el-input>
									</el-form-item>
								</el-col>
								<el-col :span="6">
									<el-form-item label="脉宽:" prop="rightAtriumPulseWidth">
										<el-input :disabled="businessStatus=='2' ? true : false" clearable v-model="form1.rightAtriumPulseWidth"
										 placeholder="请输入">
											<template slot="append">ms</template>
										</el-input>
									</el-form-item>
								</el-col>
								<el-col :span="6">
									<el-form-item label="振幅:" prop="rightAtriumAmplitude">
										<el-input :disabled="businessStatus=='2' ? true : false" clearable v-model="form1.rightAtriumAmplitude"
										 placeholder="请输入">
											<template slot="append">mv</template>
										</el-input>
									</el-form-item>
								</el-col>
								<el-col :span="6">
								</el-col>
							</el-row>
							<el-row type="flex" justify="space-around">
								<el-col :span="6">
									<el-form-item label="阻抗:" prop="rightAtriumImpedance">
										<el-input :disabled="businessStatus=='2' ? true : false" clearable v-model="form1.rightAtriumImpedance"
										 placeholder="请输入">
											<template slot="append">Ω</template>
										</el-input>
									</el-form-item>
								</el-col>
								<el-col :span="6">
									<el-form-item label="ER值:" prop="rightAtriumEr">
										<el-input :disabled="businessStatus=='2' ? true : false" clearable v-model="form1.rightAtriumEr" placeholder="请输入">
											<template slot="append">mv</template>
										</el-input>
									</el-form-item>
								</el-col>
								<el-col :span="6">
									<el-form-item label="极化电位;" prop="rightAtriumPolarizedPotential">
										<el-input :disabled="businessStatus=='2' ? true : false" clearable v-model="form1.rightAtriumPolarizedPotential"
										 placeholder="请输入">
											<template slot="append">mv</template>
										</el-input>
									</el-form-item>
								</el-col>
								<el-col :span="6">
								</el-col>
							</el-row>

						</el-card>
						<el-card class="box-card margin_top">
							<div slot="header" class="clearfix">
								<span>部位：右心室</span>
							</div>
							<el-row type="flex" justify="space-around">
								<el-col :span="6">
									<el-form-item label="阈值:" prop="rightVentricleStimulusThreshold">
										<el-input :disabled="businessStatus=='2' ? true : false" clearable v-model="form1.rightVentricleStimulusThreshold"
										 placeholder="请输入">
											<template slot="append">V</template>
										</el-input>
									</el-form-item>
								</el-col>
								<el-col :span="6">
									<el-form-item label="脉宽:" prop="rightVentriclePulseWidth">
										<el-input :disabled="businessStatus=='2' ? true : false" clearable v-model="form1.rightVentriclePulseWidth"
										 placeholder="请输入">
											<template slot="append">ms</template>
										</el-input>
									</el-form-item>
								</el-col>
								<el-col :span="6">
									<el-form-item label="振幅:" prop="rightVentricleAmplitude">
										<el-input :disabled="businessStatus=='2' ? true : false" clearable v-model="form1.rightVentricleAmplitude"
										 placeholder="请输入">
											<template slot="append">mv</template>
										</el-input>
									</el-form-item>
								</el-col>
								<el-col :span="6">
								</el-col>
							</el-row>
							<el-row type="flex" justify="space-around">
								<el-col :span="6">
									<el-form-item label="阻抗:" prop="rightVentricleImpedance">
										<el-input :disabled="businessStatus=='2' ? true : false" clearable v-model="form1.rightVentricleImpedance"
										 placeholder="请输入">
											<template slot="append">Ω</template>
										</el-input>
									</el-form-item>
								</el-col>
								<el-col :span="6">
									<el-form-item label="ER值:" prop="rightVentricleEr">
										<el-input :disabled="businessStatus=='2' ? true : false" clearable v-model="form1.rightVentricleEr"
										 placeholder="请输入">
											<template slot="append">mv</template>
										</el-input>
									</el-form-item>
								</el-col>
								<el-col :span="6">
									<el-form-item label="极化电位:" prop="rightVentriclePolarizedPotential">
										<el-input :disabled="businessStatus=='2' ? true : false" clearable v-model="form1.rightVentriclePolarizedPotential"
										 placeholder="请输入">
											<template slot="append">mv</template>
										</el-input>
									</el-form-item>
								</el-col>
								<el-col :span="6">
								</el-col>
							</el-row>
						</el-card>
						<el-card class="box-card margin_top">
							<div slot="header" class="clearfix">
								<span>部位：左心室</span>
							</div>
							<el-row type="flex" justify="space-around">
								<el-col :span="6">
									<el-form-item label="阈值:" prop="leftVentricleStimulusThreshold">
										<el-input :disabled="businessStatus=='2' ? true : false" clearable v-model="form1.leftVentricleStimulusThreshold"
										 placeholder="请输入">
											<template slot="append">V</template>
										</el-input>
									</el-form-item>
								</el-col>
								<el-col :span="6">
									<el-form-item label="脉宽:" prop="leftVentriclePulseWidth">
										<el-input :disabled="businessStatus=='2' ? true : false" clearable v-model="form1.leftVentriclePulseWidth"
										 placeholder="请输入">
											<template slot="append">ms</template>
										</el-input>
									</el-form-item>
								</el-col>
								<el-col :span="6">
									<el-form-item label="振幅:" prop="leftVentricleAmplitude">
										<el-input :disabled="businessStatus=='2' ? true : false" clearable v-model="form1.leftVentricleAmplitude"
										 placeholder="请输入">
											<template slot="append">mv</template>
										</el-input>
									</el-form-item>
								</el-col>
								<el-col :span="6">
								</el-col>
							</el-row>
							<el-row type="flex" justify="space-around">
								<el-col :span="6">
									<el-form-item label="阻抗:" prop="leftVentricleImpedance">
										<el-input :disabled="businessStatus=='2' ? true : false" clearable v-model="form1.leftVentricleImpedance"
										 placeholder="请输入">
											<template slot="append">Ω</template>
										</el-input>
									</el-form-item>
								</el-col>
								<el-col :span="6">
									<el-form-item label="ER值:" prop="leftVentricleEr">
										<el-input :disabled="businessStatus=='2' ? true : false" clearable v-model="form1.leftVentricleEr"
										 placeholder="请输入">
											<template slot="append">mv</template>
										</el-input>
									</el-form-item>
								</el-col>
								<el-col :span="6">
									<el-form-item label="极化电位:" prop="leftVentriclePolarizedPotential">
										<el-input :disabled="businessStatus=='2' ? true : false" clearable v-model="form1.leftVentriclePolarizedPotential"
										 placeholder="请输入">
											<template slot="append">mv</template>
										</el-input>
									</el-form-item>
								</el-col>
								<el-col :span="6">
								</el-col>
							</el-row>
						</el-card>
					</el-card>
					<el-card class="box-card margin_top">
						<div slot="header" class="clearfix">
							<span>植入后起搏参数</span>
						</div>
						<el-row type="flex" justify="space-around">
							<el-col :span="6">
								<el-form-item label="起搏模式:" prop="pacingMode">
									<el-input :disabled="businessStatus=='2' ? true : false" clearable v-model="form1.pacingMode" placeholder="请输入"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="6">
								<el-form-item label="基础频率:" prop="baseFrequency">
									<el-input :disabled="businessStatus=='2' ? true : false" clearable v-model="form1.baseFrequency" placeholder="请输入">
										<template slot="append">ppm</template>
									</el-input>
								</el-form-item>
							</el-col>
							<el-col :span="6">
								<el-form-item label="磁铁频率:" prop="magnetFrequency">
									<el-input :disabled="businessStatus=='2' ? true : false" clearable v-model="form1.magnetFrequency" placeholder="请输入">
										<template slot="append">ppm</template>
									</el-input>
								</el-form-item>
							</el-col>
							<el-col :span="6">
							</el-col>
						</el-row>
						<el-row type="flex" justify="space-around">
							<el-col :span="6">
								<el-form-item label="特殊功能:" prop="specialFunctions">
									<el-input :disabled="businessStatus=='2' ? true : false" clearable v-model="form1.specialFunctions"
									 placeholder="请输入"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="18">
							</el-col>
						</el-row>
						<el-card class="box-card margin_top">
							<div slot="header" class="clearfix">
								<span>PAV间期</span>
							</div>
							<el-row type="flex" justify="space-around">
								<el-col :span="6">
									<el-form-item label="心房:" prop="specialFunctions">
										<el-input :disabled="businessStatus=='2' ? true : false" clearable v-model="form1.pavIntervalAtrium"
										 placeholder="请输入">
											<template slot="append">ms</template>
										</el-input>
									</el-form-item>
								</el-col>
								<el-col :span="6">
									<el-form-item label="心室:" prop="specialFunctions">
										<el-input :disabled="businessStatus=='2' ? true : false" clearable v-model="form1.pavIntervalVentricle"
										 placeholder="请输入">
											<template slot="append">ms</template>
										</el-input>
									</el-form-item>
								</el-col>
								<el-col :span="12">
								</el-col>
							</el-row>
						</el-card>

						<el-card class="box-card margin_top">
							<div slot="header" class="clearfix">
								<span>SAV间期</span>
							</div>
							<el-row type="flex" justify="space-around">
								<el-col :span="6">
									<el-form-item label="心房:" prop="specialFunctions">
										<el-input :disabled="businessStatus=='2' ? true : false" clearable v-model="form1.savIntervalAtrium"
										 placeholder="请输入">
											<template slot="append">ms</template>
										</el-input>
									</el-form-item>
								</el-col>
								<el-col :span="6">
									<el-form-item label="心室:" prop="specialFunctions">
										<el-input :disabled="businessStatus=='2' ? true : false" clearable v-model="form1.savIntervalVentricle"
										 placeholder="请输入">
											<template slot="append">ms</template>
										</el-input>
									</el-form-item>
								</el-col>
								<el-col :span="12">
								</el-col>
							</el-row>
						</el-card>
					</el-card>
				</el-form>
			</el-card>
			<el-card class="box-card margin_top">
				<div slot="header" class="clearfix">
					<span>产品信息</span>
				</div>
				<el-form ref="form3" :model="form3" :rules="rules3" label-position="right" label-width="90px">
					<el-row type="flex" justify="space-around">
						<el-col :span="6">
							<!--						<el-form-item label="厂商产品" prop="ManufacturerModel">-->
							<!--							<el-cascader clearable :disabled="businessStatus=='2' ? true : false"  v-model="form3.ManufacturerModel"  style="width:100%" placeholder="请选择" filterable-->
							<!--							 :props="{ value: 'no', label: 'label'}" :options="ManufacturerModelOptions">-->
							<!--							</el-cascader>-->
							<!--						</el-form-item>-->
							<el-form-item label="产品/厂商:" prop="ManufacturerModel">
								<el-cascader @change="chageProductType"  filterable :props="{ value: 'no', label: 'label'}" :options="ManufacturerModelOptions" clearable v-model="form3.ManufacturerModel" style="width:100%"></el-cascader>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item label="产品序列号:" prop="productSequenceNumber">
								<el-input clearable :disabled="businessStatus=='2' ? true : false" v-model="form3.productSequenceNumber"
								 placeholder="请输入"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item label="类型:" prop="productType">
								<el-input clearable :disabled="businessStatus=='2' ? true : false" v-model="form3.productType" placeholder="请输入"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item label="电极型号:" prop="markingList">
							  <el-cascader filterable :props="{ value: 'no', label: 'label'}" :options="ManufacturerMarkingOptions" clearable v-model="form3.markingList" style="width:100%"></el-cascader>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row type="flex" justify="space-around" align="middle">
						<el-col :span="24">
							<el-form-item label="检查报告:" prop="value3">
								<!-- <el-button type="primary" @click="uploads" v-if="dataCode&&businessStatus=='1'">查看随访报告</el-button> -->
								<div style="color: #1890FF;cursor: pointer;"  @click="handles()">{{fileName}}</div>
							</el-form-item>
						</el-col>
						<!-- <el-col :span="18">
						</el-col> -->
						<!-- <el-col :span="8">
							<el-form-item label="检查报告" prop="value3">
								<span>{{fileName}}</span>
							</el-form-item>
						</el-col>
						<el-col :span="8">
							<el-button type="primary" @click="handles()">查看</el-button>
							<el-button @click="uploads" v-if="dataCode&&businessStatus=='1'">上传检查报告</el-button>
						</el-col> -->
					</el-row>
					<el-row type="flex" justify="center" style="margin-top: 20px;">
						<el-col :span="24">
							<el-button @click='cancles' v-if="businessStatus=='1'">取消</el-button>
							<el-button @click='cancles' v-if="businessStatus=='2'">返回</el-button>
							<el-button type="primary" v-if="businessStatus=='1'" @click="save">保存</el-button>
						</el-col>
					</el-row>
				</el-form>
			</el-card>
			<!-- 上传检查报告模态框 -->
			<el-dialog title="提示" :visible.sync="dialogVisible" width="40%">
				<el-row type="flex" align="middle">
					<span style="margin-right: 20px;">文件路径:</span>
					<el-upload :auto-upload="false" :on-change="handleChange" class="upload-demo" action="https://jsonplaceholder.typicode.com/posts/"
					 :on-preview="handlePreview" :on-remove="handleRemove" :before-remove="beforeRemove" multiple :limit="1"
					 :on-exceed="handleExceed" :file-list="fileList">
						<el-button size="small" type="primary">选择文件</el-button>
					</el-upload>
				</el-row>
				<span slot="footer" class="dialog-footer" style="text-align: center; ">
					<el-button @click="dialogVisible = false">取 消</el-button>
					<el-button type="primary" @click="dialogVisibleSave">上传</el-button>
				</span>
			</el-dialog>
			<!-- 倒计时 -->
			<el-dialog @close='closeDialog' title="" :visible.sync="countdownVisible" width="30%">
				<span ref="startTimer"></span>
				<span slot="footer" class="dialog-footer">
					<el-button @click="countdownVisible = false">取 消</el-button>
					<el-button type="primary" @click="closeDialog">关闭</el-button>
				</span>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	import {
		Loading
	} from 'element-ui'
	//拦截器的添加
	let loading;
	const loadingShow = function() {
		loading = Loading.service({
			lock: true,
			text: '加载中',
			spinner: 'el-icon-loading',
			background: 'rgba(0, 0, 0, 0.7)'
		});
	};
	const loadingClose = function() {
		loading.close();
	};
	export default {
		components: {},
		methods: {
			chageProductType(val){
				this.ManufacturerModelOptions.map((v,i)=>{
					if(v.children){
						if(val.length==1 && val[0]==v.no){
							this.form3.productType=v.code 
						}else{
							!v.no ? this.form3.productType='' : ""
						}
						v.children.map((v1,i1)=>{
							if(val.length==2  && val[1]==v1.no){
								this.form3.productType=v1.code;
							}else{
								!v1.no ? this.form3.productType='' : ""
							}
							if(v1.children){
								v1.children.map((v2,i2)=>{
									if(val.length==3 && val[2]==v2.no){
										this.form3.productType=v2.code 
									}else{
										!v2.no ? this.form3.productType='' : ""
									}
								})
							}
						})
					}
				})
			},
			preoperativeDiagnosisChange(event) {
				if (200049 != event) {
					this.form1.preoperativeDiagnosisInfo = null;
				}
			},
			diseaseDiagnosisInfoChange(event) {
				if (200060 != event) {
					this.form1.diseaseDiagnosisInfo = null;
				}
			},
			changeinput() {
				this.$forceUpdate()
			},
			closeDialog() {
				this.countdownVisible = false;
				this.flag = clearInterval(this.flag);
				this.timer = clearTimeout(this.timer);
				this.hour = 0;
				this.minutes = 0;
				this.seconds = 0;
				this.counts = 1;
			},
			startTimer() {
				this.seconds += 1;
				if (this.seconds >= 59) {
					this.seconds = 0;
					this.minutes = this.minutes + 1;
				}

				if (this.minutes >= 60) {
					this.minutes = 0;
					this.hour = this.hour + 1;
				}
				this.minutes < 10 ? '0' + this.minutes : this.minutes;
				this.seconds < 10 ? '0' + this.seconds : this.seconds;
				//this.startTimerHTML=`文件处理时长${this.minutes}:${this.seconds}已请求:${this.counts}次`
				this.$refs.startTimer.innerHTML = "文件处理时长" + (this.minutes < 10 ? '0' + this.minutes : this.minutes) + ':' + (this.seconds <
					10 ? '0' + this.seconds : this.seconds) + "已请求:" + this.counts + "次";
			},
			//下拉不更改值的问题
			updataOprID() {
				this.$forceUpdate()
			},
			handleChange(file, fileList) {
				let excelfileExtend = ".PDF,.pdf" //设置文件格式
				let fileExtend = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
				if (excelfileExtend.indexOf(fileExtend) <= -1) {
					this.$refs.upload.clearFiles();
					this.$message.error('文件格式错误')
					return false
				}
				this.fileParam = file.raw;
				this.inspectionReportName = file.name;
				this.fileType = "1";
			},
			//厂商型号
			async QueryManufacturerModel(){
				let menuId=JSON.parse(localStorage.getItem('menuList'))
				let obj={
					menuId: menuId.PatientManagement,
				}
			    let res = await this.$Http.ManufacturerModel(obj);
				if (res.code == '200') {
					this.ManufacturerModelOptions = res.data;
				} else {
					this.$message.error(res.message);
				}
			},
			//厂商型号
			async QueryManufacturerMarking(){
				let menuId=JSON.parse(localStorage.getItem('menuList'))
				let obj={
					menuId: menuId.PatientManagement,
				}
			    let res = await this.$Http.ManufacturerMarking(obj);
				if (res.code == '200') {
					this.ManufacturerMarkingOptions = res.data;
				} else {
					this.$message.error(res.message);
				}
			},
			//厂商型号
// 			async QueryManufacturerModel(level) {
// 				let menuId = JSON.parse(localStorage.getItem('menuList'))
// 				let obj = {
// 					menuId: menuId.PatientManagement,
// 					//level: level
// 				}
// 				let res = await this.$Http.Select(obj);
// 				if (res.code == '200') {
// 					this.ManufacturerModelOptions = res.data;
// 				} else {
// 					this.$message.error(res.message);
// 				}
// 			},
			//省市区接口
			async QueryProvinces() {
				let res = await this.$Http.Provinces({});
				if (res.code == '200') {
					this.ProvincesOptions = res.data;
				} else {
					this.$message.error(res.message);
				}
			},
			//性别接口
			async QuerySexType() {
				let res = await this.$Http.DepartmentType({
					parentCode: "100002"
				});
				if (res.code == '200') {
					this.SexOptions = res.data;
				} else {
					this.$message.error(res.message);
				}
			},
			//术前诊断
			async QueryDiagnosis() {
				let res = await this.$Http.DepartmentType({
					parentCode: "100003"
				});
				if (res.code == '200') {
					this.DiagnosisOptions = res.data;
				} else {
					this.$message.error(res.message);
				}
			},
			//疾病诊断
			async QueryDiseaseDiagnosis() {
				let res = await this.$Http.DepartmentType({
					parentCode: "100017"
				});
				if (res.code == '200') {
					this.DiseaseDiagnosisOptions = res.data;
				} else {
					this.$message.error(res.message);
				}
			},
			//手术类型
			async QueryOperation() {
				let res = await this.$Http.DepartmentType({
					parentCode: "100004"
				});
				if (res.code == '200') {
					this.OperationOptions = res.data;
				} else {
					this.$message.error(res.message);
				}
			},
			//术式
			async QueryWarlock() {
				let res = await this.$Http.DepartmentType({
					parentCode: "100005"
				});
				if (res.code == '200') {
					this.WarlockOptions = res.data;
				} else {
					this.$message.error(res.message);
				}
			},
			//术者
			async QueryUserCurrency() {
				let menuId = JSON.parse(localStorage.getItem('menuList'))
				let obj = {
					menuId: menuId.PatientManagement,
				}
				let res = await this.$Http.UserCurrency(obj);
				if (res.code == '200') {
					this.UserCurrencyOptions = res.data;
				} else {
					this.$message.error(res.message);
				}
			},
			//是否更换产品
			async QueryproductReplacement() {
				let res = await this.$Http.DepartmentType({
					parentCode: "100006"
				});
				if (res.code == '200') {
					this.productReplacementOptions = res.data;
				} else {
					this.$message.error(res.message);
				}
			},
			//是否升级
			async Queryupgrade() {
				let res = await this.$Http.DepartmentType({
					parentCode: "100006"
				});
				if (res.code == '200') {
					this.upgradeOptions = res.data;
				} else {
					this.$message.error(res.message);
				}
			},
			//有无发现其他植入物
			async QueryDepartimplantsOther() {
				let res = await this.$Http.DepartmentType({
					parentCode: "100007"
				});
				if (res.code == '200') {
					this.implantsOtherOptions = res.data;
				} else {
					this.$message.error(res.message);
				}
			},
			handles() {
				let url = this.oss;
				if (url) {
					window.open(url, '_blank')
				} else {
					this.$message.warning("暂无网络地址");
				}
			},
			//查询病患信息
			async QueryPatientsBasic(dataCode) {
				let info = {
					dataCode: dataCode
				}
				let res = await this.$Http.PatientsQueryPageInfoBasic(info);
				if (res.code == 200) {
					//基本信息
					this.form = res.data.patientsInfoBasicVo;
					this.form.Provinces = [res.data.patientsInfoBasicVo.province, res.data.patientsInfoBasicVo.city];
					//医疗信息
					this.form1 = res.data.patientsMedicalInfoVo;
					//术者返回数据
					this.form1.userId1 = res.data.patientOperatorInfoVo.length >= 1 ? res.data.patientOperatorInfoVo[0].userId : "";
					this.form1.userId2 = res.data.patientOperatorInfoVo.length >= 2 ? res.data.patientOperatorInfoVo[1].userId : "";
					this.form1.userId3 = res.data.patientOperatorInfoVo.length >= 3 ? res.data.patientOperatorInfoVo[2].userId : "";
					//产品信息
					this.form3 = res.data.patientsProductInfoVo;
					if (res.data.patientsProductInfoVo.manufacturerId && res.data.patientsProductInfoVo.manufacturerProductId) {
						this.form3.ManufacturerModel = [res.data.patientsProductInfoVo.manufacturerId,res.data.patientsProductInfoVo.manufacturerProductId
						];
					} else {
						this.form3.ManufacturerModel = [res.data.patientsProductInfoVo.manufacturerId];
					}

					//图片
					this.fileName = res.data.patientsProductInfoVo.inspectionReportName;
					this.oss = res.data.inspectionReportUrl;
					//this.oss = res.data.patientsProductInfoVo.inspectionReportUrl;
					this.local = res.data.patientsProductInfoVo.inspectionReportLocalUrl;
					this.patientsFollowInsertDto = res.data.patientsFollowVo;
					this.productParametersBasicInsertDto = res.data.productParametersBasicVo;
					this.productPartitionSettingsInsertDto = res.data.productPartitionSettingsVo;
					this.parameterSettingInsertDto = res.data.parameterSettingVo;
					this.vtfEventInsertDto = res.data.vtfEventVos;
					this.$message.success(res.message);
					this.refSubCat = {
						departmentsId: res.data.patientsProductInfoVo.departmentsId,
						hospitalId: res.data.patientsProductInfoVo.hospitalId,
						userId: res.data.patientsProductInfoVo.userId
					}
				} else {
					this.$message.error(res.message);
				}
			},
			//取消
			cancles() {
				this.$router.push({
					path: '/PatientManagement'
				})
			},
			//保存
			save() {
				if (!this.form.medicalNo) {
					return this.$message.error("请输入病例号！");
				}
				if(this.form3.ManufacturerModel.length<=0){
					return this.$message.error("请选择厂商/产品！");
				}
				if(!this.form3.productType){
					return this.$message.error("请输入类型！");
				}
				if(!this.form3.productSequenceNumber){
					return this.$message.error("请输入序列号！");
				}
				if(this.form3.markingList.length<=0){
					return this.$message.error("请输入电极型号！");
				}
				if (this.errorCode !== "0") {
					if (this.dataCode) {
						this.$confirm('文件预计处理时间为3-5分钟是否等待?', '提示', {
							closeOnClickModal: false,
							confirmButtonText: '等待',
							cancelButtonText: '保存',
							type: 'warning'
						}).then(() => {
							this.dialogVisible = false;
							this.countdownVisible = true;
							this.flag = setInterval(this.startTimer, 1000);
							this.getCodeCount();
						}).catch(() => {
							this.dialogVisible = false;
							this.QueryPatientsInsertBy();
							this.$message({
								type: 'info',
								message: '已保存'
							});
						});
					} else {
						this.QueryPatientsInsertBy();
					}

				} else {
					this.QueryPatientsInsertBy();
				}
			},
			//查询病患信息
			async QueryPatientsInsertBy() {
				//病患基本信息数据处理
				if (!this.form.Provinces) {
					this.form.Provinces = [];
				}
				let {
					Provinces,
					...obj2
				} = this.form;
				let obj1 = {
					province: this.form.Provinces.length > 1 ? this.form.Provinces[0] : "",
					city: this.form.Provinces.length >= 2 ? this.form.Provinces[1] : ""
				}

				let patientsInfoBasic = {
					...obj2,
					...obj1

				}
				// 医疗信息数据处理
				// 术者数据处理
				let patientOperatorInfo = []
				if (this.form1.userId1) {
					patientOperatorInfo.push({
						userId: this.form1.userId1,
						sort: 1
					})
				}
				if (this.form1.userId2) {
					patientOperatorInfo.push({
						userId: this.form1.userId2,
						sort: 2
					})
				}
				if (this.form1.userId3) {
					patientOperatorInfo.push({
						userId: this.form1.userId3,
						sort: 3
					})
				}
				//产品信息处理
				let obj3 = {
					manufacturerId: this.form3.ManufacturerModel.length >= 1 ? this.form3.ManufacturerModel[0] : "",
					manufacturerProductId: this.form3.ManufacturerModel.length >= 2 ? this.form3.ManufacturerModel[1] : "",
					manufacturerMarkingId:this.form3.markingList&&this.form3.markingList.length>=1 ? this.form3.markingList[0] : "",
					markingNameId:this.form3.markingList&&this.form3.markingList.length>=2 ? this.form3.markingList[1] : "",
					markingModelId:this.form3.markingList&&this.form3.markingList.length>=3 ? this.form3.markingList[2] : "",
					markingList:this.form3.markingList.toString(),
					inspectionReportName: this.inspectionReportName,
					inspectionReportUrl: this.oss,
					inspectionReportLocalUrl: this.local
				}
				let {
					ManufacturerModel,
					...obj4
				} = this.form3;
				let patientsProductInfo = {
					...obj4,
					...obj3,
				}
				let info = {
					patientsInfoBasic: patientsInfoBasic, // 病患基本信息
					patientsMedicalInfo: this.form1, //医疗信息
					patientOperatorInfo: patientOperatorInfo, //术者信息
					//patientsProductInfo:this.patientsProductInfo,
					patientsFollowInsertDto: this.patientsFollowInsertDto,
					productParametersBasicInsertDto: this.productParametersBasicInsertDto,
					productPartitionSettingsInsertDto: this.productPartitionSettingsInsertDto,
					parameterSettingInsertDto: this.parameterSettingInsertDto,
					vtfEventInsertDto: this.vtfEventInsertDto,
					patientsProductInfo: patientsProductInfo, //产品信息
					fileType: this.dataCode ? this.fileType : "1",
					inspectionReportId: this.inspectionReportId,
					type: this.dataCode ? "2" : "1",
				}

				console.log(info)
				let res = await this.$Http.PatientsInsertByInfo(info);
				if (res.code == 200) {
					this.$message.success(res.message);
					localStorage.removeItem("ocrName2");
					this.$router.push({
						path: '/PatientManagement'
					})
				} else {
					this.$message.error(res.message);
				}
			},

			getCodeCount() {
				let _this = this
				this.timer = setTimeout(() => {
					_this.QueryPatientsinspectionReport();
					this.counts += 1;
				}, 180000)
			},
			//查看本地OCR
			viewOCR() {

			},
			handleRemove(file, fileList) {
				console.log(file, fileList);
				this.fileParam = {};
				this.inspectionReportName = "";
				this.fileType = "2";
			},
			handlePreview(file) {
				console.log(file);
			},
			handleExceed(files, fileList) {
				this.$message.warning(`只能上传一个文件!`);
			},
			beforeRemove(file, fileList) {
				return this.$confirm(`确定移除 ${ file.name }？`);
			},
			//新增上传PDF
			async QueryPatientsinspectionReport() {
				clearTimeout(this.timer)
				this.ocrName = localStorage.getItem("ocrName2");
				if (!this.form.medicalNo) {
					return this.$message.error("请输入病例号！");
				}
				let info = {
					fileParam: this.fileParam,
					ocrName: this.ocrName || "", //this.form.medicalNo
					...this.refSubCat
				}
				console.log(info, "info")
				let res = await this.$Http.PatientsinspectionReport(info, true);
				if (res.code == '200') {
					this.dialogVisible = false;
					// 检查报告显示
					this.reportDisplay = true;
					this.ocrName = res.data.ocrName;
					localStorage.setItem("ocrName2", res.data.ocrName);
					this.patientsFollowInsertDto = res.data.patientsFollowInsertDto;
					this.productParametersBasicInsertDto = res.data.productParametersBasicInsertDto;
					this.productPartitionSettingsInsertDto = res.data.productPartitionSettingsInsertDto;
					this.parameterSettingInsertDto = res.data.parameterSettingInsertDto;
					this.vtfEventInsertDto = res.data.vtfEventInsertDto;
					this.oss = res.data.patientsProductInfo.inspectionReportUrl;
					this.local = res.data.patientsProductInfo.inspectionReportLocalUrl;
					this.fileName = res.data.patientsProductInfo.inspectionReportName;
					this.inspectionReportId = res.data.patientsProductInfo.inspectionReportId;
					//this.patientsProductInfo=res.data.patientsProductInfo;
					this.form3 = res.data.patientsProductInfo;
					if (res.data.patientsProductInfo.manufacturerId && res.data.patientsProductInfo.manufacturerProductId) {
						this.form3.ManufacturerModel = [res.data.patientsProductInfo.manufacturerId, res.data.patientsProductInfo.manufacturerProductId];
					} else if(res.data.patientsProductInfo.manufacturerId){
						this.form3.ManufacturerModel = [res.data.patientsProductInfo.manufacturerId];
					}else{
						this.form3.ManufacturerModel = []
					}
					this.errorCode = res.data.patientsProductInfo.errorCode;
					if (res.data.patientsProductInfo.errorCode !== "0") {
						if (res.data.patientsProductInfo.errorCode == "-20") {
							this.$message.success(res.data.patientsProductInfo.errorMessage);
							this.getCodeCount();
						} else {
							this.$message.error(res.data.patientsProductInfo.errorMessage);
						}

					} else {
						this.timer = clearTimeout(this.timer)
						this.countdownVisible = false;
						this.flag = clearInterval(this.flag)
						this.$message.success(res.data.patientsProductInfo.message);
					}
				} else {
					this.$message.error(res.message);
				}
			},
			// 保存上传
			dialogVisibleSave() {
				this.ocrName = localStorage.removeItem("ocrName2");
				this.QueryPatientsinspectionReport();
			},
			// 上传检查报告
			uploads() {
				this.dialogVisible = true;
				this.fileParam = {};
				this.fileList = [];
			},
			//分页组件
			pages(current, size) {

			},
			//查看该医生的都患者
			CheckPatient() {
				this.$router.push({
					path: '/DoctorsPatient'
				})
			},
			// 搜索
			querys() {

			},
			//重置
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			// 选中事件
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
		},
		beforeDestroy() {
			this.timer = clearTimeout(this.timer);
			this.flag = clearInterval(this.flag);
			this.ocrName = localStorage.removeItem("ocrName2");
		},
		data() {
			return {
				DiseaseDiagnosisOptions:[],
				businessStatus: "",
				dataCode: "",
				types: localStorage.getItem("type"),
				fileType: "2",
				menuCode: JSON.parse(localStorage.getItem('menuList'))[this.$route.name],
				counts: 1,
				countdownVisible: false,
				hour: 0,
				minutes: 0,
				seconds: 0,
				flag: null,
				errorCode: "0",
				timer: null,
				oss: "",
				local: "",
				fileName: "", //上传PDF名称
				implantsOtherOptions: [], //有无发现其他植入物
				productReplacementOptions: [], //是否更换产品
				upgradeOptions: [], //是否升级
				//厂商型号
				ManufacturerModelOptions: [],
				//术者返回数据
				patientOperatorInfoVo: [],
				//术者
				UserCurrencyOptions: [],
				//术士
				WarlockOptions: [],
				//手术类型
				OperationOptions: [],
				//术前诊断
				DiagnosisOptions: [],
				//省市区
				ProvincesOptions: [],
				//性别
				SexOptions: [],
				reportDisplay: false,
				fileList: [],
				// 上传体检报告模态框
				dialogVisible: false,
				//术者数据
				options4: [],
				options5: [],
				options6: [],
				//form病患基本信息
				form: {
					Provinces: [],
				},
				//form病患基本信息表单验证规则
				rules: {},
				//form医疗信息
				form1: {},
				// form医疗信息表单验证规则
				rules1: {},
				//form产品信息
				form3: {
					ManufacturerModel: [],
					markingList:[]
				},
				// form产品信息表单验证规则
				rules3: {},
				//性别数据
				ProductPackageData: [],
				inputValue: '',
				InventoryStatusData: [],
				ManufacturerMarkingOptions:[]
			}
		},
		created() {},
		mounted() {
			//厂商型号
			this.QueryManufacturerModel(4);
			//厂商型号
			 this.QueryManufacturerMarking();
			//术者
			this.QueryUserCurrency();
			//省市区
			this.QueryProvinces();
			//性别接口
			this.QuerySexType();
			//术前诊断
			this.QueryDiagnosis();
			this.QueryDiseaseDiagnosis();
			//手术类型
			this.QueryOperation();
			//术士
			this.QueryWarlock();
			//获取路由传参的值
			this.dataCode = this.$route.query.dataCode;
			this.businessStatus = this.$route.query.businessStatus;
			//是否更换产品
			this.QueryproductReplacement();
			//是否升级
			this.Queryupgrade();
			//有无发现其他植入物
			this.QueryDepartimplantsOther();

			if (this.dataCode) {
				//获取病患信息
				this.QueryPatientsBasic(this.dataCode);
			} else {
				this.businessStatus = "1";
				this.InspectionCurrencyList = JSON.parse(localStorage.getItem('InspectionCurrencyList'));
				this.ocrName = this.InspectionCurrencyList.ocrName;
				this.patientsFollowInsertDto = this.InspectionCurrencyList.patientsFollowInsertDto;
				this.productParametersBasicInsertDto = this.InspectionCurrencyList.productParametersBasicInsertDto;
				this.productPartitionSettingsInsertDto = this.InspectionCurrencyList.productPartitionSettingsInsertDto;
				this.parameterSettingInsertDto = this.InspectionCurrencyList.parameterSettingInsertDto;
				this.vtfEventInsertDto = this.InspectionCurrencyList.vtfEventInsertDto;
				this.oss = this.InspectionCurrencyList.patientsProductInfo.inspectionReportUrl;
				this.local = this.InspectionCurrencyList.patientsProductInfo.inspectionReportLocalUrl;
				this.fileName = this.InspectionCurrencyList.patientsProductInfo.inspectionReportName;
				this.form3 = this.InspectionCurrencyList.patientsProductInfo;
				this.form.medicalNo = this.InspectionCurrencyList.patientsFollowInsertDto.medicalNo;
				this.form = this.InspectionCurrencyList.patientsInfoBasicVo;
				if (this.InspectionCurrencyList.patientsProductInfo.manufacturerId && this.InspectionCurrencyList.patientsProductInfo
					.manufacturerProductId) {
					this.form3.ManufacturerModel = [this.InspectionCurrencyList.patientsProductInfo.manufacturerId, this.InspectionCurrencyList
						.patientsProductInfo.manufacturerProductId
					];
				} else {
					this.form3.ManufacturerModel = [this.InspectionCurrencyList.patientsProductInfo.manufacturerId];
				}
				this.errorCode = this.InspectionCurrencyList.patientsProductInfo.errorCode;
			}
		}
	}
</script>

<style scoped>
	.box-card{
		margin-top: 10px;
	}
	.el-tag+.el-tag {
		margin-left: 10px;
	}

	.button-new-tag {
		margin-left: 10px;
		height: 32px;
		line-height: 30px;
		padding-top: 0;
		padding-bottom: 0;
	}

	.input-new-tag {
		width: 90px;
		margin-left: 10px;
		vertical-align: bottom;
	}
</style>
